import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'

import { QueryClientProvider, QueryClient } from 'react-query'
import { BrowserRouter as Router } from 'react-router-dom'

import { Provider } from 'jotai'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Router>
    <QueryClientProvider client={queryClient}>
      <Provider>
        <App />
      </Provider>
    </QueryClientProvider>
  </Router>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
