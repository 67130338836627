export const FORGOT_PASSWORD = '/user/forgot-password'

export const CHECK_PASSWORD = '/user/verify-password'

export const USER_LOGIN = '/user/login'

export const ADD_CITY = '/add-city'

export const ADD_BASEFLOW_STEPS_CITY = '/run-base-flow'
export const ADD_ATSPM_STEPS_CITY = '/run-atspm-flow'

export const VIEW_ALL_CITY = '/view-all-city'

export const USER_RESET_PASSWORD = '/user/reset-password'

export const USER_VIEW_ALL_MANAGER = '/user/view-all-manager'

export const USER_ENABLE_MANAGER = '/user/enable-manager'

export const USER_DELETE_MANAGER = '/user/delete-manager'

export const DELETE_CITY = '/delete-city'

export const CITY_LOGS = '/city-logs'

export const USER_ADD_TEAM_MEMBER = '/user/add-team-member'

export const USER_VIEW_PROFILE = '/user/view-profile'

export const EDIT_CITY_BOUNDARY = '/edit-city-boundary'

export const UPDATE_CITY_MAP = '/run-update-user-flow'

export const GET_CORRIDORS = '/get-corridors'
// get-corridor-osm-new
export const CORRIDOR_GET_CORRIDOR_OSM = '/corridor/get-corridor-osm'
export const CORRIDOR_GET_CORRIDOR_OSM_NEW = '/corridor/get-corridor-osm-new'

export const CORRIDOR_UPDATE_CORRIDOR_TYPE = '/corridor/update-corridor-type'

export const TIMING_PLAN_GET_TIMING_PLAN = '/timingplan/get-timing-plan'

export const CORRIDOR_CORRIDOR_ALERTS = '/corridor/corridor-alerts'

export const CORRIDOR_CORRIDOR_SLOWNESS_PERFORMANCE = '/corridor/corridor-slowness-performance'

export const CORRIDOR_CORRIDOR_SLOWNESS_PERFORMANCE_COMBINE = '/corridor/corridor-slowness-performance-combine'

export const CORRIDOR_CORRIDOR_TRAVEL_TIME_PERFORMANCE = '/corridor/corridor-travel-time-performance'

export const CORRIDOR_CORRIDOR_TRAVEL_TIME_PROFILE = '/corridor/corridor-travel-time-profile'

export const CORRIDOR_CORRIDOR_TRAVEL_TIME_PROFILE_COMPARE = '/corridor/corridor-travel-time-profile-compare'
export const CORRIDOR_CORRIDOR_STOP_PROBABILITY_COMPARE = '/corridor/corridor-stop-probability-compare'

export const CORRIDOR_GET_CORRIDORS_WEEKLY_VS_RATIO_NEW = '/corridor/get-corridors-weekly-vs-ratio-new'

export const CORRIDOR_GET_CORRIDORS_WEEKLY_VS_RATIO_COMPARISION = '/corridor/get-corridors-weekly-vs-ratio-comparision'

export const CORRIDOR_CORRIDOR_SIGNAL_LIST = '/corridor/corridor-signal-list'

export const CORRIDOR_GET_SIGNAL_DATA = '/corridor/get-signal-data'
export const CORRIDOR_GET_STOP_PROBABILITY = '/corridor/get-corridors-stop-probability'

export const CORRIDOR_GET_VOLUME_DATA = '/corridor/get-volume-data'

export const SIGNALS_GET_SIGNALS_COUNTS_DATA = '/signals/get-signals-counts-data'

export const SIGNALS_GET_SIGNALS_DELAY_DATA = '/signals/get-signals-delay-data'
export const SIGNALS_GET_SPLIT_FAILURE = '/signals/get-signals-split-failure'

export const TIMING_PLAN_GET_ALL_VERSIONS = '/timingplan/get-all-versions'

export const TIMING_PLAN_EXPORT_TIMING_PLAN = '/timingplan/export-timing-plan'

export const CORRIDOR_GET_CORRIDOR_CITY_BY_ID = '/corridor/get-corridor-city-by-id'

export const TIMING_PLAN_GET_TIMING_PLAN_BY_VERSIONS = '/timingplan/get-timing-plan-by-versions'

export const TIMING_PLAN_ACCEPT_TIMING_PLAN = '/timingplan/accept-timing-plan'

export const TIMING_PLAN_UNDO_TIMING_PLAN = '/timingplan/undo-timing-plan'

export const TIMING_PLAN_GENERATE_TIMING_PLAN = '/timingplan/generate-timing-plan'

export const USER_USER_REQUEST_GENERATE_PLAN = '/user/user-request-generate-plan'

export const EVENT_UPDATE_EVENT_DETAILS = '/event/update-event-details'

export const EVENT_GET_EVENT_DATA_MONTH_VIEW = '/event/get-event-data-month-view'

export const EVENT_GET_EVENT_DATA_DAY_VIEW = '/event/get-event-data-day-view'

export const EVENT_GET_EVENT_LIST = '/event/get-event-list'

export const CORRIDOR_ADD_SIGNAL_FILE_FROM_ADMIN = '/corridor/add-signal-file-from-admin'

export const CORRIDOR_ADD_CORRIDOR_FILE_FROM_ADMIN = '/corridor/add-corridors-file-from-admin'

export const CORRIDOR_ADD_SIGNAL_PERFORMANCE_FROM_ADMIN = '/corridor/add-signal-performance-from-admin'

export const CORRIDOR_UPLOAD_CORRIDOR_PRIORITY_CSV = '/corridor/upload-corridor-priority-csv'

export const CORRIDOR_ADD_CORRIDOR_FROM_JSON = '/corridor/add-corridors-from-json'

export const CORRIDOR_ADD_SIGNALS_FROM_JSON = '/corridor/add-signals-from-json'

export const CORRIDOR_ADD_CORRIDOR_PERFORMANCE_FROM_JSON = '/corridor/add-corridor-performance-from-json'

export const CORRIDOR_ADD_SIGNAL_PERFORMANCE_FROM_JSON = '/corridor/add-signal-performance-from-json'

export const TIMING_PLAN_ADD_TIMING_PLAN_FROM_JSON = '/timingplan/add-timing-plan-from-json'

export const TIMING_PLAN_ADD_TIMING_PLAN_PERFORMANCE = '/timingplan/add-timing-plan-performance'

export const EVENT_ADD_EVENT_DATA = '/event/add-event-data'
export const CORRIDOR_ADD_ECD_DATA_FROM_JSON = '/corridor/add-ecd-data-from-json'

export const GET_EVENT_DETAIL_BY_DATE = '/event/get-event-detail-by-date'

export const CORRIDOR_DELETE_CORRIDOR_FROM_JSON = '/corridor/delete-corridors-from-json'

export const CORRIDOR_DELETE_SIGNALS_FROM_JSON = '/corridor/delete-signals-from-json'

export const CORRIDOR_DELETE_CORRIDOR_PERFORMANCE_FROM_JSON = '/corridor/delete-corridor-performance-from-json'

export const CORRIDOR_DELETE_SIGNAL_PERFORMANCE_FROM_JSON = '/corridor/delete-signal-performance-from-json'

export const TIMING_PLAN_DELETE_TIMING_PLAN_FROM_JSON = '/timingplan/delete-timing-plan-from-json'

export const DELETE_TIMING_PLAN_PERFORMANCE = '/timingplan/delete-timing-plan-performance'

export const EVENT_DELETE_EVENT_DATA = '/event/delete-event-data'

export const USER_ADD_TRAFFIC_MANAGER = '/user/add-traffic-manager'

export const USER_UPDATE_TEAM_MEMBER = '/user/update-team-member'

// export const SIGNAL_UPDATE_SIGNAL_TYPE = '/corridor/update-signal-type'

// export const REMOVE_SIGNAL = '/corridor/remove-signal'

export const ADD_INTERSECTION_INTO_CORRIDOR = '/corridor/add-intersection'

export const ADD_CORRIDORS_NEW_DATA = '/corridor/add-corridors-new-data'

export const SAVE_ALL_CORRIDOR_DATA = '/corridor/save-all-corridor-data'

export const SAVE_UNASSIGNED_INTERSECTION = '/corridor/add-unassigned-intersection'

export const RUN_EVENT_DATA_GENERATION_FLOW = '/automatedFileUpload/event_data_analysis'
export const CORRIDOR_RUN_BASEFLOW = '/run-base-flow'
export const CORRIDOR_RUN_ATSPMFLOW = '/run-atspm-flow'
export const CORRIDOR_RUN_HEREMATCH = '/run-here-match-flow'
export const CORRIDOR_RUN_CORRIDOR_PERFORMANCE = '/run-corridor-performance-flow'
export const CORRIDOR_RUN_BASEFLOW_CORRIDOR_PERFORMANCE = '/run-baseflow-corridor-performance-flow'
export const AUTOMATED_FILE_GENERATION = 'automatedFileUpload/file_uploading'
export const UPLOAD_META_FILES = 'automatedFileUpload/meta_data_upload'
export const GET_ECD_DATA = '/corridor/get-ecd-data'
export const GET_ECD_PLANS = '/corridor/get-ecd-plans'
export const ALL_SIGNAL_LIST = '/corridor/all-signal-list'
export const ALL_AVAILABLE_CORRIDOR_PERFORMANCE_DATES = '/corridor/corridor-performance-get-available-dates'
export const GET_CITY_DATA = '/get-city-data'
export const UPDATE_CITY_DATA = '/update-city-data'

export const GET_OVERALL_PERFORMANCE = '/report/overall_performance'
export const GET_DELAY = '/report/slowness_details'
export const GET_STOP_PROBABILITY = '/report/stop_probability'
export const GET_SPLIT_FAILURE = '/report/split_failure'
export const GET_SPLIT_FAILURE_DETAILS = '/report/split_failure_details'

export const GET_ALERT_DATA = '/alert/get_alert_data'
export const UPLOAD_ALERTS_DATA = '/alert/add_alert_from_json'
export const SAVE_TRESHOLD_VALUE = '/alert/save_threshold'
export const GET_ALL_TRESHOLD_VALUE = '/alert/get_all_threshold_value'
export const CONTACT_US = '/user/contact_us'

export const GET_TIMING_PLAN_COMPARISION = 'timingplan/get-timing-plan-comparison '

export const RUN_WEJO_PIPELINE = '/wejoPipeline/initate-wejo-pipeline'
export const UPLOAD_WEJO_META_FILES = '/wejoPipeline/wejo-meta-data-upload'
export const CLEAR_CITY_LOGS = '/clear-city-logs'

export const ALERTS_SIGNALS_SPLIT_FAILURE = 'signals/get-alert-signals-split-failure'

export const GET_BUCKET_LIST = '/wejoPipeline/get-bucketlist'
