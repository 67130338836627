import React, { useState } from 'react'
import { Formik } from 'formik'
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { FORGOT_PASSWORD } from 'Utils/EndPointConst'
import * as Yup from 'yup'
import ApiInstance from 'Api'
import Grid from '@mui/material/Grid'
import 'react-toastify/dist/ReactToastify.css'
import classes from './Login.module.css'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

export default function ForgotPassword(props) {
  const [errorResponseMessage, setResponseMessage] = useState({
    show: false,
    msg: '',
  })

  const errorMsg = {
    padding: '4px',
    fontSize: '14px',
    color: '#f7f0f0',
    borderRadius: '4px',
    textAlign: 'center',
    background: '#808080',
  }

  return (
    <>
      <div className={classes.login}>
        <div className={classes.form}>
          <h1>Forgot Password?</h1>
          <p>No worries, we’ll send you reset instructions.</p>

          {errorResponseMessage.show && <h4 style={errorMsg}>{errorResponseMessage.msg}</h4>}
          <Formik
            initialValues={{ email: '' }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true)
              let user = {
                email: values.email,
                password: values.password,
              }
              ApiInstance.post(FORGOT_PASSWORD, user)
                .then((res) => {
                  setResponseMessage({
                    show: true,
                    msg: 'Your request to change password has been sent to the Admin. We will send you the password link after approval from Admin.',
                  })
                  toast.success('Your request has been successfully sent')
                })
                .catch((err) => {
                  setSubmitting(false)
                  toast.error('Email does not match.')
                })
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email().required('Email is required.'),
            })}
          >
            {(props) => {
              const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit } = props
              return (
                <form onSubmit={handleSubmit} className={classes.formBox}>
                  <div className={classes.contactFormTitle}>Email</div>
                  <div className={classes.contactFormGridOne}>
                    <div>
                      <input
                        name="email"
                        id="email"
                        variant="outlined"
                        className={classes.textField}
                        placeholder="Enter your email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <p>{errors.email && touched.email && errors.email}</p>
                    </div>
                  </div>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}></Grid>
                  </Grid>

                  <button id="submit" type="submit" className={classes.submit} disabled={isSubmitting}>
                    Send Request
                  </button>
                  <Grid container>
                    <Grid item xs style={{ marginTop: '1rem', textAlign: 'center' }}>
                      <Link to="/login" variant="body2" id="forgotPassword" className={classes.linkColor}>
                        <ArrowBackIcon /> Back to log in
                      </Link>
                    </Grid>
                  </Grid>
                </form>
              )
            }}
          </Formik>
        </div>
        <div className={classes.logo}>
          <img src="/Icons/Etalyc_Branding.svg" alt="Etalyc" width="200px" className="logo"></img>
          <p>Welcome to Hyperflow dashboard</p>
        </div>
      </div>
      <ToastContainer />
    </>
  )
}
