import Axios from 'axios'
import Cookies from 'js-cookie'
const ApiInstance = Axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
})

ApiInstance.interceptors.request.use(
  (config) => {
    const token = Cookies.get('access')
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

ApiInstance.interceptors.response.use(
  (response) => {
    return response
  },
  async function (error) {
    const originalRequest = error.config
    if (error && error.response && error.response.status === 401 && !originalRequest._retry) {
      localStorage.clear()
      Cookies.remove('access')
      window.location.href = '/login'
      originalRequest._retry = true
      const res = await Axios.post(`${process.env.REACT_APP_API_URL}auth/refresh`, {
        refresh: Cookies.get('access'),
      })
      if (res.status === 200) {
        Cookies.set('access', res.data.access, { expires: 1 })
        ApiInstance.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.access
        return ApiInstance(originalRequest)
      }
    }
    return Promise.reject(error)
  }
)

export default ApiInstance
