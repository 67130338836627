import React from 'react'
import Cookies from 'js-cookie'
import { Navigate, Route, Routes } from 'react-router-dom'
import ForgotPassword from 'Components/ForgotPassword'
import { headerArray } from 'Utils'

const LayoutMain = React.lazy(() => import('./LayoutMain'))
const Layout = React.lazy(() => import('Components/Module/Layout/Layout'))
const AdminLayout = React.lazy(() => import('Components/SuperAdmin/Layout/AdminLayout'))

const Missing = React.lazy(() => import('./Missing'))
const Login = React.lazy(() => import('Components/Login'))
const RequireAuth = React.lazy(() => import('./RequireAuth'))
const Map = React.lazy(() => import('Components/Module/Map/Map'))
const AddCity = React.lazy(() => import('Components/SuperAdmin/AddCity'))
const Alerts = React.lazy(() => import('Components/Module/Alerts/Alerts'))
const Reports = React.lazy(() => import('Components/Module/Reports/Reports'))
const CityLogs = React.lazy(() => import('Components/SuperAdmin/CityLogs'))
const Setting = React.lazy(() => import('Components/Module/Setting/Setting'))
const Signals = React.lazy(() => import('Components/Module/Signals/Signals'))
const Contact = React.lazy(() => import('Components/Module/Contact-us/Contact'))
const AllUserList = React.lazy(() => import('Components/SuperAdmin/AllUserList'))
const AddMember = React.lazy(() => import('Components/Module/Setting/AddMember'))
const EventsGroup = React.lazy(() => import('Components/Module/Events/Events'))
const AddManager = React.lazy(() => import('Components/TrafficEngineer/AddManager'))
const UploadCsvFile = React.lazy(() => import('Components/SuperAdmin/UploadCsv/UploadCsv'))
const UploadJsonFile = React.lazy(() => import('Components/SuperAdmin/UploadJson/UploadJson'))
const AdminDashboard = React.lazy(() => import('Components/SuperAdmin/AdminDashoard'))
const SignalGroup = React.lazy(() => import('Components/Module/SignalGroup/SignalGroup'))
const Notification = React.lazy(() => import('Components/Module/Notifications/Notification'))
const Intersections = React.lazy(() => import('Components/Module/Intersections/Intersections'))
const DataGeneration = React.lazy(() => import('Components/SuperAdmin/UploadDataGeneration/DataGeneration'))
const IntersectionDetails = React.lazy(() => import('Components/Module/Intersections/IntersectionDetails'))
const TimingPlan = React.lazy(() => import('Components/Module/TimingPlan/TimingPlan'))
const PerformanceMeasure = React.lazy(() => import('Components/Module/PerformanceMeasure/PerformanceMeasure'))
const CorridorComparision = React.lazy(() => import('Components/Module/CorridorComparision/CorridorComparision'))
const Password = React.lazy(() => import('Components/Module/Setting/Password'))
const CityInformation = React.lazy(() => import('Components/SuperAdmin/CityInformation/CityInformation'))

function RoutesMain() {
  const token = Cookies.get('access')
  return (
    <>
      <Routes>
        <Route
          path="/login"
          element={
            <React.Suspense fallback={<p>...</p>}>
              <Login />
            </React.Suspense>
          }
        />

        <Route
          path="/forgot-password"
          element={
            <React.Suspense fallback={<p>Forgot Password</p>}>
              <ForgotPassword />
            </React.Suspense>
          }
        />
        {!token && <Route path="/" element={<Navigate to="/login" replace />} />}
        {!token && <Route path="/forgot-password" element={<ForgotPassword />} />}

        <Route
          path="/"
          element={
            <React.Suspense fallback={'...'}>
              <LayoutMain />
            </React.Suspense>
          }
        >
          {(localStorage.getItem('userRole') === 'TRAFFIC_MANAGER' ||
            localStorage.getItem('userRole') === 'TRAFFIC_ENGINEER') && (
            <Route element={<RequireAuth allowedRoles={['TRAFFIC_MANAGER', 'TRAFFIC_ENGINEER']} />}>
              <Route path="/" element={<Navigate to="alerts" replace />} />

              <Route
                path="map"
                element={
                  <React.Suspense fallback={<Layout loading={true} />}>
                    <Map />
                  </React.Suspense>
                }
              />

              <Route
                path="report"
                element={
                  <React.Suspense fallback={<Layout loading={true} headerArray={headerArray} />}>
                    <Reports />
                  </React.Suspense>
                }
              />
              <Route
                path="alerts"
                element={
                  <React.Suspense fallback={<Layout loading={true} headerArray={headerArray} />}>
                    <Alerts />
                  </React.Suspense>
                }
              />
              <Route
                path="signals"
                element={
                  <React.Suspense fallback={<Layout loading={true} headerArray={headerArray} />}>
                    <Signals />
                  </React.Suspense>
                }
              />
              <Route
                path="settings"
                element={
                  <React.Suspense fallback={<Layout loading={true} />}>
                    <Setting />
                  </React.Suspense>
                }
              />
              <Route
                path="contact-us"
                element={
                  <React.Suspense fallback={<Layout loading={true} />}>
                    <Contact />
                  </React.Suspense>
                }
              />
              <Route
                path="events"
                element={
                  <React.Suspense fallback={<Layout loading={true} headerArray={headerArray} />}>
                    <EventsGroup />
                  </React.Suspense>
                }
              />
              <Route
                path="settings/add"
                element={
                  <React.Suspense fallback={<Layout loading={true} />}>
                    <AddMember />
                  </React.Suspense>
                }
              />
              <Route
                path="settings/password"
                element={
                  <React.Suspense fallback={<Layout loading={true} />}>
                    <Password />
                  </React.Suspense>
                }
              />
              <Route
                path="notification"
                element={
                  <React.Suspense fallback={<Layout loading={true} />}>
                    <Notification />
                  </React.Suspense>
                }
              />
              <Route
                path="signal-groups"
                element={
                  <React.Suspense fallback={<Layout loading={true} headerArray={headerArray} />}>
                    <SignalGroup />
                  </React.Suspense>
                }
              />
              <Route
                path="signal-groups/corridor/:id/performance-measure"
                element={
                  <React.Suspense fallback={<Layout loading={true} headerArray={headerArray} />}>
                    <PerformanceMeasure />
                  </React.Suspense>
                }
              />
              <Route
                // signal-groups/corridor/U3RvbmVicm9vayBQYXJrd2F5/intersection
                path="signal-groups/corridor/:id/intersection"
                element={
                  <React.Suspense fallback={<Layout loading={true} headerArray={headerArray} />}>
                    <Intersections />
                  </React.Suspense>
                }
              />
              <Route
                path="signal-groups/corridor/:id/intersection/:signalId"
                element={
                  <React.Suspense fallback={<Layout loading={true} headerArray={headerArray} />}>
                    <IntersectionDetails />
                  </React.Suspense>
                }
              />
              {/* <Route
                path="signal-groups/corridor/:id/timing-plan"
                element={
                  <React.Suspense fallback={<Layout loading={true} />}>
                    <TimingPlanManager />
                  </React.Suspense>
                }
              /> */}
              <Route
                path="signal-groups/corridor/:id/timing-plan"
                element={
                  <React.Suspense fallback={<Layout loading={true} headerArray={headerArray} />}>
                    <TimingPlan />
                  </React.Suspense>
                }
              />
              <Route
                path="signal-groups/corridor/:id/comparision"
                element={
                  <React.Suspense fallback={<Layout loading={true} headerArray={headerArray} />}>
                    <CorridorComparision />
                  </React.Suspense>
                }
              />
            </Route>
          )}
          {/*--------------------------- Super Admin--------------------------- */}
          {localStorage.getItem('userRole') === 'SUPER_ADMIN' && (
            <Route element={<RequireAuth allowedRoles={['SUPER_ADMIN']} />}>
              <Route path="/" element={<Navigate to="/dashboard" replace />} />
              <Route
                path="/"
                element={
                  <React.Suspense fallback={<AdminLayout loading={true} />}>
                    <AdminDashboard />
                  </React.Suspense>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <React.Suspense fallback={<AdminLayout loading={true} />}>
                    <AdminDashboard />
                  </React.Suspense>
                }
              />
              <Route
                path="/dashboard/all-users"
                element={
                  <React.Suspense fallback={<AllUserList loading={true} />}>
                    <AllUserList />
                  </React.Suspense>
                }
              />
              <Route
                path="/add-city"
                element={
                  <React.Suspense fallback={<AdminLayout loading={true} />}>
                    <AddCity />
                  </React.Suspense>
                }
              />
              <Route
                path="/users"
                element={
                  <React.Suspense fallback={<AdminLayout loading={true} />}>
                    <AllUserList />
                  </React.Suspense>
                }
              />

              <Route
                path="/user/:name/:osmid"
                element={
                  <React.Suspense fallback={<AdminLayout loading={true} />}>
                    <AllUserList />
                  </React.Suspense>
                }
              />

              <Route
                path="/city-logs/:name/:osmid/:state"
                element={
                  <React.Suspense fallback={<AdminLayout loading={true} />}>
                    <CityLogs />
                  </React.Suspense>
                }
              />

              <Route
                path="/city-dashboard/user/"
                element={
                  <React.Suspense fallback={<AdminLayout loading={true} />}>
                    <AddManager />
                  </React.Suspense>
                }
              />
              <Route
                path="/upload-json/:name/:osmid/:state"
                element={
                  <React.Suspense fallback={<AdminLayout loading={true} />}>
                    <UploadJsonFile />
                  </React.Suspense>
                }
              />
              <Route
                path="/city-info/:name/:osmid/:state"
                element={
                  <React.Suspense fallback={<AdminLayout loading={true} />}>
                    <CityInformation />
                  </React.Suspense>
                }
              />
              <Route
                path="/city-dashboard/:name/:osmid/:state"
                element={
                  <React.Suspense fallback={<AdminLayout loading={true} />}>{/* <CityDashboard /> */}</React.Suspense>
                }
              />
              <Route
                path="/data-generate/:name/:osmid/:state"
                element={
                  <React.Suspense fallback={<AdminLayout loading={true} />}>
                    <DataGeneration />
                  </React.Suspense>
                }
              />

              <Route
                path="/upload-csv/:name/:osmid/:state"
                element={
                  <React.Suspense fallback={<AdminLayout loading={true} />}>
                    <UploadCsvFile />
                  </React.Suspense>
                }
              />
            </Route>
          )}
          {/* catch all */}
          <Route path="*" element={<Missing />} />
        </Route>
      </Routes>
    </>
  )
}

export default RoutesMain
