import React from 'react'

import './App.css'
import './Common.module.css'
import './Components/css/Responsive.css'
import './Components/Module/ManageCity.css'
import './Components/TrafficEngineer/Users.css'
import './Components/TrafficEngineer/Timing.css'
import RoutesMain from 'Authentication/RoutesMain'
// import AuthenticationCheck from 'Components/Layout/AuthenticationCheck'
// Create Context for city-boundaries/
export const EditCityContext = React.createContext(null)

const App = () => {
  return (
    <>
      <RoutesMain />
    </>
  )
}

export default App
