export function verifyEmail(email) {
  const emailReg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return emailReg.test(email)
}

export const headerArray = [
  { name: 'Alerts', image: `/Icons/Vectoralers.svg`, path: '/alerts', d_image: '/Icons/darkalerts.svg' },
  {
    name: 'Signal Groups',
    image: '/Icons/Vectorsg.svg',
    path: '/signal-groups',
    d_image: '/Icons/darksignalgroup.svg',
  },
  { name: 'Events', image: '/Icons/Vectorevent.svg', path: '/events', d_image: '/Icons/darkevents.svg' },
  { name: 'Signals', image: '/Icons/Vectorsignals.svg', path: '/signals', d_image: '/Icons/darksignals.svg' },
]

export const corridorHeaderArray = [
  { name: 'Performance Measure', path: 'performance-measure' },
  { name: 'Intersections', path: 'intersection' },
  { name: 'Timing Plan Manager', path: 'timing-plan' },
  { name: 'Corridor Comparison', path: 'comparision' },
]

export const limit = [
  { label: '10', value: '10' },
  { label: '5', value: '5' },
  { label: '3', value: '3' },
]

export const sorting = [
  { label: 'Overall', value: 'Overall' },
  { label: 'Split Failure', value: 'SplitFailure' },
  { label: 'Delay Metric', value: 'DelayPerSignal' },
  { label: 'Stop Metric', value: 'StopProbability' },
]

export const convertSelectedValueToTime = (value) => {
  if (value && !isNaN(value)) {
    let time = Math.floor(parseInt(value) / 2)
    let min = parseInt(value) % 2 === 0 ? '00' : '30'
    let inputTime = time + ':' + min
    return inputTime
  } else {
    return '00:00'
  }
}

export const getGeneratedPlanId = (value, data) => {
  let dataReturn = ''

  let inputTime = parseInt(convertSelectedValueToTime(value).replace(':', ''))
  data?.data.forEach((obj) => {
    obj.deployment_period.forEach((deployment_period) => {
      let start_time = parseInt(deployment_period.StartTime.replace(':', ''))
      let end_time = parseInt(deployment_period.EndTime.replace(':', ''))

      if (inputTime >= start_time && inputTime < end_time) {
        dataReturn = obj.planid
        // setGeneratedPlanId(obj.planid)
      }
    })
  })
  return dataReturn
}
// Array for alert navigation bar
export const alertNavigationArr = [
  { id: 'split-failure', headerText: 'Split Failure', active: 'SplitFailure' },
  { id: 'progression-failure', headerText: 'Progression Failures', active: 'ProgressionFailure' },
  { id: 'main-street-delay', headerText: 'Main Street Delay', active: 'MainStreetDelay' },
  { id: 'detector-failure', headerText: 'Detector Failures', active: 'DetectorFailure' },
]

// List of timezone city wise
export const cityTimezoneArr = [
  { timezone: 'America/Chicago', utc: '' },
  { timezone: 'America/Denver', utc: '' },
  { timezone: 'America/Boise', utc: '' },
  { timezone: 'America/Los_Angeles', utc: '' },
  { timezone: 'America/Phoenix', utc: '' },
  { timezone: 'America/New_York', utc: '' },
  { timezone: 'America/Indiana/Indianapolis', utc: '' },
  { timezone: 'America/Kentucky/Louisville', utc: '' },
  { timezone: 'America/Detroit', utc: '' },
  { timezone: 'America/Menominee', utc: '' },
  { timezone: 'America/Anchorage', utc: '' },
  { timezone: 'America/Sitka', utc: '' },
  { timezone: 'America/Nome', utc: '' },
  { timezone: 'Pacific/Honolulu', utc: '' },
  { timezone: 'America/Juneau', utc: '' },
  { timezone: 'America/Adak', utc: '' },
  { timezone: 'America/Yakutat', utc: '' },
]
